import React, { useState } from 'react';
import { useAppDispatch } from '../../../store';
import { updateIntegerState } from '../../../reducers/configurationReducer';

const IntConfig = ({ state }: any) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string>("");

  const handleChange = (e: any) => {
    dispatch(updateIntegerState(parseInt(e.target.value)));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      setError("");
      handleChange(e);
      return;
    }

    const value = parseInt(inputValue);
    if (!isNaN(value)) {
      // Check if state.key is "appointment_expired_time"
      if (state?.key === 'appointment_expired_time' && value > 48) {
        e.target.value = '';
        setError("Number should be less than or equal to 48 hours");
      } else {
        setError("");
        handleChange(e); 
      }
    }
  };

  const placeholder = state?.key === 'appointment_expired_time' ? 'Hours' : 'Time';

    const maxValue = state?.key === 'appointment_expired_time' ? 48 : undefined;

  return (
    <div className='row'>
      <div className='col'>
        <input
          type='number'
          min={1}
          max={maxValue}
          value={state?.value}
          onChange={handleInputChange}
          className='form-control form-control-lg form-control-solid'
          placeholder={placeholder}
        />
        {error && (
          <div style={{ color: 'red', fontSize: '0.875rem', marginTop: '5px' }}>
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default IntConfig;
