import React from 'react'
import InfoWrapper from '../../../modules/common/SectionInfo'
import { snake_to_title } from '../../../../util/helper'
import IntConfig from './IntConfig'
import Json1Config from './Json1Config'
import Json2Config from './Json2Config'
import Json4Config from './Json4Config'
import Json5Config from './Json5Config'
import NotificationConfigurations from './NotificationConfigurations'
import Json6Config from './Json6Config'
import Json3Config from './Json3Config'
import ArrayConfig from './ArrayConfig'
import SMSConfig from './SMSConfig'
import TimeConfig from './TimeConfig'

interface stateProps {
  state: {
    id: number
    key: string
    value: any
    type: string
  }
}

const Configurations = ({ state }: stateProps) => {
  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-body px-9 py-2'>
        <div className='mt-4'>
          <InfoWrapper
            title={`${snake_to_title(state?.key)}${state?.key === 'appointment_expired_time' && state?.type === 'integer' ? ' (hours)' : ''}`}
          >
            {state?.type === 'integer' ? (
              <IntConfig state={state} />
            ) : state?.type === 'json1' ? (
              <Json1Config state={state} />
            ) : state?.type === 'json2' ? (
              <Json2Config state={state} />
            ) : state?.type === 'json4' ? (
              <Json4Config state={state} />
            ) : state?.type === 'json5' ? (
              <Json5Config state={state} />
            ) : state?.type === 'json6' ? (
              <Json6Config state={state} />
            ) : state?.type === 'notifications' ? (
              <NotificationConfigurations state={state} />
            ) : state?.type === 'json3' ? (
              <Json3Config state={state} />
            ) : state?.type === 'array' ? (
              <ArrayConfig state={state} />
            ) : state?.type === 'sms' ? (
              <SMSConfig state={state} />
            ) : state?.type === 'time' ? (
              <TimeConfig state={state} />
            ) : (
              <></>
            )}
          </InfoWrapper>
          {/* <div className='d-flex justify-content-end py-6'>
            <button
              // type='submit'
              className='btn btn-primary'
            //   onClick={handleOpen}
              // disabled={prevData == defaultValues}
            >
              Save
            </button>
            <ConfModal open={open} handleClose={handleClose} handleSubmit={handleSubmit} />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Configurations;